<template>
  <div class="process" @click="ri = null">
    <div class="process-top">
      <div class="process-app" v-if="info">
        <img :src="base + info.icon" class="process-app-icon" v-if="info.icon">
        <div class="process-app-name">{{info.name}}</div>
      </div>
      <h3 class="process-top-title">审批流程设置</h3>
      <a-space class="process-btn">
        <a-button @click="cancel">取消</a-button>
        <a-divider type="vertical" />
        <a-button type="primary" @click="save">保存</a-button>
      </a-space>
    </div>
    <div class="process-detail" v-if="info">
      <process-list
        :nodes="flow"
        :extra-temp="info.extraTemp"
        editable
        :has-level="hasLevel"
        @edit="showEdit"
        @change="handleChange"></process-list>
    </div>
    <a-drawer
        :title="`${item ? getNodeRoleTitle(item.type) : ''}设置`"
        placement="right"
        :mask-closable="false"
        :visible="item !== null"
        :width="600"
        :body-style="{paddingBottom: '69px'}"
        @close="onClose">
      <div class="node-conf" v-if="item && item.type == 'condition'">
        <div class="flex-box">
          <a-input class="flex-grow" v-model="item.title" placeholder="请输入条件分支名称"></a-input>
          <a-select v-model="item.priority" :options="item.priorityOptions" class="node-conf-priority"></a-select>
        </div>
        <div class="node-conf-item">
          <div class="node-conf-label">同时满足以下条件</div>
          <div class="node-conf-condition">
            <div class="node-condition-list" v-if="item.conditions">
              <div class="node-condition-item" v-for="(con, i) in item.conditions" :key="con.con_id">
                <form-area
                  :ref="`${con.con_id}_form`"
                  class="node-condition-form"
                  layout="inline"
                  hide-btn
                  link
                  :items="con.form"
                  :entity="con.value">
                </form-area>
                <a-icon class="node-condition-remove" type="close-circle" theme="filled" @click="removeCondition(i)" v-if="item.conditions.length > 1"/>
              </div>
            </div>
            <a-space class="add-condition" @click="addCondition">
              <a-icon type="plus" />
              <span>添加条件</span>
            </a-space>
          </div>
        </div>
      </div>
      <div class="node-conf" v-else-if="item">
        <a-input v-model="item.title" placeholder="请输入节点名称"></a-input>
        <div class="node-conf-form">
          <div class="node-conf-item" v-if="item.type == 1 || item.type == 2">
            <a-radio-group v-model="item.leader_option">
              <a-radio :style="{width: '31.5%', height: '30px', lineHeight: '30px'}" :value="r.value" v-for="r in leaderOptions" :key="r.value">{{r.label}}</a-radio>
            </a-radio-group>
          </div>
          <div class="node-conf-item" v-if="!item.leader_option">
            <div class="node-conf-label">添加成员<span>不能超过100人</span></div>
            <select-book multiple type="user" v-model="item.ids"></select-book>
          </div>
          <div class="node-conf-item" v-else-if="item.leader_option == 3">
            <div class="node-conf-label">指定标签<span>将由标签内所有成员审批</span></div>
            <select-book type="tag" v-model="item.tags"></select-book>
          </div>
          <div class="node-conf-item" v-else-if="item.leader_option == 4">
            <div class="node-conf-label">选人方式</div>
            <a-radio-group v-model="item.elect_type" :options="electTypeOptions" />
          </div>
          <template v-else-if="item.leader_option == 6">
            <div class="node-conf-item">
              <div class="node-conf-label">关联志工团/组<span>指定关联志工志工团/组</span></div>
              <a-select v-model="item.group_key" :options="groupOptions" placeholder="请选择" style="width: 240px"></a-select>
            </div>
            <div class="node-conf-item">
              <div class="node-conf-label">指定身份<span>下列指定身份可审批</span></div>
              <a-checkbox-group v-model="item.volunteer_type" :options="groupTypeOption" />
            </div>
          </template>
          <template v-if="item.type == 1">
            <div class="node-conf-item">
              <div class="node-conf-label">指定下一个节点<span>当前节点可以选择下一个节点审批人</span></div>
              <a-checkbox v-model="item.custom_next">允许当前节点选择下一个节点</a-checkbox>
            </div>
            <div class="node-conf-item" v-if="hasApprovalType(item)">
              <div class="node-conf-label">多人审批方式</div>
              <a-radio-group v-model="item.approval_type">
                <a-radio :style="radioStyle" :value="r.value" v-for="r in options" :key="r.value">{{r.label}}</a-radio>
              </a-radio-group>
            </div>
          </template>
          <template v-else-if="item.type == 2">
            <div class="node-conf-item">
              <div class="node-conf-label">申请人自选<span>申请人自行选择抄送人</span></div>
              <a-checkbox v-model="item.custom_cc">允许申请人自选抄送人</a-checkbox>
            </div>
            <div class="node-conf-item">
              <div class="node-conf-label">申请人本人</div>
              <a-checkbox v-model="item.self_cc">抄送给申请人本人</a-checkbox>
            </div>
          </template>
          <div class="node-conf-item" v-if="item.type == 1 || item.type == 3">
            <div class="node-conf-label">车辆/包厢<span>审批时可指定车辆/包厢</span></div>
            <a-radio-group v-model="item.assign_item">
              <a-radio value="car">车辆</a-radio>
              <a-radio value="box">包厢</a-radio>
              <a-radio value="">不指定</a-radio>
            </a-radio-group>
          </div>
        </div>
      </div>
      <a-space class="footer">
        <a-button @click="onClose">取消</a-button>
        <a-button type="primary" @click="confirmNodeSet">确定</a-button>
      </a-space>
    </a-drawer>
  </div>
</template>

<script>
    import {clone, getKeyTitle, isEmpty, randomString} from "../common/js/tool";
import processList from "../components/process-list";
import {base} from "../components/icon-select";
import {
  numberTypeItem,
  conditionComponents,
  attendanceConditionForm,
  leaderOptions,
  electTypeOptions,
  approveOptions,
  hasApprovalType,
  requireIds,
  getNodeRoleTitle,
  updateNumberForm
} from "../common/constant/process";
import { registerCatOption } from "../common/hr/volunteer";
import volunteerItems from "../common/js/expand/volunteer";

export default {
  name: "Process",
  components: {
    processList
  },
  provide() {
    return {
      process: this
    }
  },
  data() {
    return {
      info: null,
      nodes: [],
      flow: [],
      item: null,
      event: null,
      ri: null,
      base,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      leaderOptions,
      electTypeOptions,
      groupTypeOption: registerCatOption.filter(opt => [1,2,3,5].indexOf(opt.value) >= 0),
      options: approveOptions,
      tempOptions: [],
      attendanceTypes: [],
      temps: null,
      visible: false,
      selectTemp: undefined,
      hasLevel: false
    }
  },
  computed: {
    groupOptions() {
      let res = [
        {label: "申请人所在志工团/组", value: "user_volunteer_group"}
      ];
      if(this.info) {
        const form = this.info.form;
        form.forEach(item => {
          switch (item.component) {
            case "volunteer_modify": {
                const items = volunteerItems[item.type || "status"] || [];
                res.push(...items.filter(c => c.group).map(c => Object({label: c.label, value: c.key})));
              }
              break;
            case "select":
              if(!item.mode || item.mode == "selector") {
                res.push({label: item.label, value: item.key})
              }
              break;
          }
        });
      }
      return res;
    }
  },
  created() {
    this.getTemp();
  },
  methods: {
    hasApprovalType,
    getNodeRoleTitle,
    delNodeTemp(i) {
      this.item.tempList.splice(i, 1);
    },
    confirmSelect() {
      let selectTemp = this.selectTemp;
      let temp = this.temps.find(t => t.key == selectTemp);
      let item = {
        id: temp.key,
        type: temp.type,
        is_oa: temp.is_oa,
        oa_type: temp.oa_type,
        name: temp.title,
        icon: temp.icon,
      }
      if(this.item) {
        let list = this.item.tempList
        if(list) {
          if(list.findIndex(t => t.id == item.id) >= 0 ){
            this.$message.warn("不能重复添加同一模板");
            return;
          } else {
            list.push(item);
          }
        } else {
          list = [item];
        }
        this.$set(this.item, 'tempList', list);
      }
      this.visible = false;
    },
    cancel() {
      this.$router.back();
    },
    save() {
      let nodes = this.nodes;
      let flag = this.checkNodes(nodes);
      if(flag) {
        let list = clone(nodes);
        this.dealNodes(list);
        let id = this.$route.params.id;
        let url = `/admin/apply-temp/${id}`;
        this.$axios({
          url,
          method: 'PATCH',
          data: {
            flow: JSON.stringify(list)
          }
        }).then(() => {
          this.$message.success("保存成功");
          this.$router.back();
          this.$store.commit("changeUpdate", {type: "temp"})
        })
      } else {
        this.$message.warning('审批流程存在错误的节点，请检查标记为红色的节点，修复后再试');
      }
    },
    dealNodes(nodes) {
      nodes.forEach(n => {
        if(n.type == 1) {
          n.custom_next = n.custom_next ? 1 : 0;
        } else if(n.type == 2) {
          n.custom_cc = n.custom_cc ? 1 : 0;
          n.self_cc = n.self_cc ? 1 : 0;
        } else if(n.type == 'condition') {
          if(n.conditions && n.conditions.length > 0) {
            n.conditions.forEach(con => {
              delete con.form;
            });
          }
          delete n.tempOptions;
          delete n.priorityOptions;
          delete n.priority;
          delete n.default;
        } else if(n.type == 'branch') {
          n.children.forEach(b => {
            this.dealNodes(b.nodes);
          });
        } else if(n.type == 'level') {
            delete n.ids;
        }
        if(n.leader_option == 3 && n.tags) {
          let tag = n.tags[0];
          n.tag = {id: tag.id, name: tag.name};
          if(tag.real_ids) {
            n.ids = tag.real_ids.split(",");
          }
          delete n.tags;
        }
        delete n.error;
      });
    },
    confirmNodeSet() {
      let item = this.item;
      if(item.type == 'condition') {
        this.checkCondition(item).then(res => {
          this.$set(item, "error", false);
          item.conditions.forEach((con, index) => {
            con.value = res[index];
            let option = item.tempOptions.find(opt => opt.key == con.selectKey);
            con.type = option.type;
            con.label = option.title;
            if(con.type == 2) {
              con.value.typeText = con.value.type.map(t => this.attendanceTypes.find(at => at.value == t).label);
            }
          });
          delete item.default;
          this.updateNodeSet(item);
        }).catch(err => {
          this.$set(item, "error", true);
          this.$message.warning(err);
        });
      } else {
        let error = this.checkNode(item);
        if(error) {
          this.$message.warning(error);
        } else {
          this.updateNodeSet(item);
        }
      }
    },
    updateNodeSet(item) {
      let {node, branch, parent} = this.event;
      let nodes = this.nodes;
      if(branch) {
        nodes = branch.nodes;
      }
      // 非指定成员节点 清空已选成员列表
      if(!isEmpty(item.leader_option) && item.leader_option > 0) {
        item.ids = [];
      }
      // 志工团身份将组和身份展示信息存储 用于前端展示
      if(item.leader_option == 6) {
          item.group_name = this.getGroupName(item.group_key);
          item.volunteer_type_name = this.getVolunteerTypeText(item.volunteer_type);
      }
      let index = nodes.findIndex(n => n.node_id == node.node_id);
      // 当前审批节点设置可以指定下一个节点 则设置其为最后一个节点
      if(item.type == 1 && item.custom_next) {
        this.$confirm({
          title: '提示',
          content: '设置此节点可选择下一个节点，则后续节点不会生效，将自动删除！',
          onOk: () => {
            nodes.splice(index, 1, item);
            nodes.splice(index + 1);
            this.onClose();
          }
        })
      } else {
        // 优先级发生了变化 重新排序
        if(item.type == 'condition' && item.priority != node.priority) {
          let list = parent.children;
          let offset = item.priority - branch.priority;
          // 找出当前更新的分支
          let cb = list.find(b => b.priority == branch.priority);
          // 当前分支下标
          list.forEach(b => {
            let p = b.priority;
            // 优先级降低 将当前分支至目标优先级分支都-1
            if(offset > 0) {
              if(p > branch.priority && p <= item.priority) {
                b.priority -= 1;
              }
            } else if(offset < 0) {
              // 优先级提升 将当前分支至目标优先级分支都+1
              if(p >= item.priority && p < branch.priority) {
                b.priority += 1;
              }
            }
          });
          cb.priority = item.priority;
          list.sort((a, b) => {
            return a.priority - b.priority;
          });
          delete item.priority;
          this.$set(parent, 'children', list);
        }
        nodes.splice(index, 1, item);
        this.onClose();
      }
    },
    checkCondition(item) {
      if(!item.title) {
        return Promise.reject('请输入条件分支名称');
      } else if(!item.conditions || item.conditions.length <= 0) {
        return Promise.reject('至少添加一个条件');
      } else {
        let pro = [];
        item.conditions.forEach(con => {
          pro.push(this.$refs[`${con.con_id}_form`][0].handleConfirm());
        })
        return Promise.all(pro);
      }
    },
    checkNodes(nodes) {
      let flag = true;
      for(let i = 0, l = nodes.length; i < l; i ++) {
        let node = nodes[i];
        if(node.type == 'branch') {
          let branchList = node.children;
          for(let j = 0, len = branchList.length; j < len; j++) {
            let branch = branchList[j];
            if(branch.default) {
              branch.nodes[0].default = true;
            }
            if(!this.checkNodes(branch.nodes)) {
              flag = false;
            }
          }
        } else {
          if(this.checkNode(node)) {
            flag = false;
          }
        }
      }
      return flag;
    },
    checkNode(item) {
      let error = null;
      if(item.type == 'condition') {
        if(!item.title) {
          error = '请输入条件分支名称';
        } else if(!item.default && (!item.conditions || item.conditions.length <= 0)) {
          error = '至少添加一个条件';
        }
      } else if(item.type > 0) {
        if(!item.title) {
          error = '请输入节点名称';
        } else if(hasApprovalType(item) && !item.approval_type) {
          error = '请选择多人审批方式';
        } else if(item.leader_option == 3 && (!item.tags || item.tags.length <= 0)) {
            error = '请选择标签';
        } else if(requireIds(item) && (!item.ids || item.ids.length == 0)) {
            error = `请选择${getNodeRoleTitle(item.type)}`;
        } else if(item.leader_option == 4 && !item.elect_type) {
            error = '请选择申请人自选选人方式';
        } else if(item.leader_option == 6) {
            if(!item.group_key) {
                error = '请选择关联的志工团选择控件';
            } else if(!item.volunteer_type || item.volunteer_type.length == 0) {
                error = '请指定志工团身份';
            }
        }
      }
      this.$set(item, "error", !!error);
      return error;
    },
    addCondition() {
      this.addNodeCondition();
    },
    removeCondition(i) {
      let conditions = this.item.conditions;
      if(conditions.length <= 1) return;
      conditions.splice(i, 1);
      this.updateConditionOptions();
    },
    handleChange(nodes) {
      this.nodes = nodes;
    },
    showEdit(e){
      // 暂存分支、节点及父节点对象 用于更新
      this.event = e;
      let {node, branch, parent, tempId} = e;
      let item = clone(node);
      if(item.type == 1) {
        item.custom_next = !!item.custom_next;
      } else if(item.type == 2) {
        item.custom_cc = !!item.custom_cc;
        item.self_cc = !!item.self_cc;
      }
      if(branch && item.type == 'condition') {
        let max = parent.children.length - 1;
        item.priority = branch.priority;
        item.priorityOptions = new Array(max).fill(0).map((n, i) => {
          return {
            key: i + 1,
            title: `优先级${i + 1}`
          }
        });
        if(tempId) {
          const temp = this.info.extraTemp.find(t => t.id == tempId);
          if(temp) {
            if(!temp.tempOptions) {
              let tempOptions = [];
              if(temp.detail) {
                try {
                  const form = JSON.parse(temp.detail);
                  tempOptions = this.getTempOptions(form);
                } catch (e) {
                  console.error(e);
                }
              }
              temp.tempOptions = tempOptions;
            }
            item.tempOptions = clone(temp.tempOptions);
          }
        } else {
          item.tempOptions = clone(this.info.tempOptions);
        }
        if(item.conditions) {
          this.updateConditionOptions(item)
        } else {
          this.addNodeCondition(item);
        }
      }
      this.item = item;
    },
    addNodeCondition(item) {
      item = item || this.item;
      item.conditions = item.conditions || [];
      let condition = {
        selectKey: null,
        form: [],
        value: {},
        con_id: this.generateConditionId()
      }
      condition.form.push(this.getKeyTypeItem(item, condition));
      item.conditions.push(condition);
    },
    getKeyTypeItem(item, condition) {
      let _this = this;
      return {
        key: 'key',
        label: '',
        component: 'a-select',
        props: {
          options: item.tempOptions,
          placeholder: "请选择条件选项"
        },
        style: {
          width: '120px'
        },
        listeners: {
          change: function (val) {
            condition.selectKey = val;
            _this.updateConditionOptions();
            let form = {key: val};
            if(this.entity.key == val) {
              form = {...clone(this.entity), ...form};
            }
            this.form = form;
          }
        },
        rules: [{ required: true, message: '请选择条件选项', trigger: 'change' }]
      }
    },
    updateConditionOptions(node) {
      let item = node || this.item;
      let conditions = item.conditions;
      let keys = conditions.reduce((acc, val) => {
        if(val.selectKey) {
          acc.push(val.selectKey)
        }
        return acc;
      }, []);
      item.tempOptions.forEach(opt => {
        if(keys.indexOf(opt.key) >= 0) {
          opt.disabled = true;
        } else {
          opt.disabled = false;
        }
      });
      conditions.forEach(con => {
        let form = [this.getKeyTypeItem(item, con)]
        form[0].props.options = item.tempOptions;
        let opt = item.tempOptions.find(opt => opt.key == con.selectKey);
        if(opt) {
          form = form.concat(clone(opt.form));
          con.type = opt.type;
        }
        // 数字条件特殊处理
        if(con.type == 3 || con.type == 4) {
          let val = con.value.type;
          updateNumberForm(val, form);
        }
        if(node) {
          con.form = form
        } else {
          this.$set(con, 'form', form);
        }
      });
    },
    generateConditionId() {
      let str = `con_${randomString(4)}`;
      while (this.item && this.item.conditions.some(c => c.con_id == str)) {
        str = this.generateConditionId();
      }
      return str;
    },
    onClose() {
      this.item = null;
      this.event = null;
    },
    getTemp() {
      let detail = this.$store.getters.detail;
      let id = this.$route.params.id;
      if(detail && detail.type == 'temp' && detail.obj.id == id) {
        this.$store.commit("setDetail", null);
        this.dealInfo(detail.obj);
      } else {
        this.getDetail();
      }
    },
    getDetail() {
      let url = `/admin/apply-temp/${this.$route.params.id}`;
      this.$axios(url).then(this.dealInfo);
    },
    dealInfo(info) {
      if(info.flow) {
        try {
          let flow = JSON.parse(info.flow);
          this.flow = flow;
          this.nodes = flow;
        } catch (e) {
          console.error(e);
        }
      }
      if(info.detail) {
        try {
          info.form = JSON.parse(info.detail);
        } catch (e) {
          console.error(e);
        }
      }
      this.info = info;
      // 有请假组件获取所有请假类型
      if(info.form && info.form.findIndex(c => c.component == 'attendance' && c.type == 'leave') >= 0) {
        this.$axios('/holiday?pageSize=1000').then(res => {
          this.attendanceTypes = res.data.map(item => {
            return {
              label: item.name,
              value: item.id
            }
          });
          info.tempOptions = this.getTempOptions(info.form);
        });
      } else {
        info.tempOptions = this.getTempOptions(info.form);
      }
    },
    /**
     * 获取关联志工组选择控件名称
     * @param key 控件key
     * @returns {string}
     */
    getGroupName(key) {
      let res = "";
      const opt = this.groupOptions.find(o => o.value == key);
      res = opt ? opt.label : "";
      return res;
    },
    /**
     * 获取指定志工团身份展示名称
     * @param types 指定身份数组
     * @returns {string}
     */
    getVolunteerTypeText(types) {
      types = types || [];
      return types.map(t => getKeyTitle(registerCatOption, t, "value", "label")).join("、");
    },
    getTempOptions(conf) {
      if(!conf) return [];
      let options = [{
        key: 'applicant',
        title: '申请人',
        type: 1,
        form: [
          {
            key: 'applicant',
            label: '为',
            component: 'select-book',
            props: {
              type: 'book',
              multiple: true
            },
            rules: [{ required: true, message: '至少设置一个申请人', trigger: 'change' }]
          }
        ]
      }];
      conf.forEach(item => {
        switch (item.component) {
          // 请假组件
          case 'attendance': {
            let leaveTypeForm = clone(attendanceConditionForm.leave_type);
            leaveTypeForm[0].props.options = this.attendanceTypes;
            options.push({
              key: 'leave_type',
              title: '请假类型',
              type: 2,
              form: leaveTypeForm
            },{
              key: 'day_num',
              title: '请假天数',
              type: 3,
              form: clone(attendanceConditionForm.day_num)
            });
            break;
          }
          // 用餐组件
          case 'meal':
            options.push({
              key: 'meal_count',
              title: '用餐次数',
              type: 4,
              form: [clone(numberTypeItem)]
            },{
              key: 'meal_people',
              title: '用餐人数',
              type: 4,
              form: [clone(numberTypeItem)]
            });
            break;
          default: {
            let condition = conditionComponents.find(c => c.component == item.component);
            if(condition && (item.component != 'input' || item.type == 'number') && (item.component != 'select' || item.mode == 'selector')) {
              let opt = {
                key: item.key,
                title: item.label,
                type: condition.type,
                form: clone(condition.form)
              };
              if(item.component == 'select' || item.component == 'multiple-select') {
                let selectOptions = item.options.map(o => {
                  return {
                    label: o,
                    value: o
                  }
                });
                if(item.component == 'select') {
                  opt.form[0].props.options = selectOptions;
                } else {
                  opt.form[1].props.options = selectOptions;
                }
              }
              options.push(opt);
            }
          }
        }
      });
      return options;
    },
  }
}
</script>

<style scoped lang="less">
.process {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.process-top {
  position: relative;
  padding: 24px 0;
  box-shadow: 0 1px 0 0 #dbdbdb;
}
.process-app {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 36px;
  bottom: 0;
  margin: auto 0;
}
.process-app-icon {
  width: 48px;
  height: 48px;
  border-radius: 4px;
}
.process-app-name {
  margin-left: 12px;
  font-size: 16px;
}
.process-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  right:36px;
  margin: auto 0;
}
.process-top-title {
  margin: 0;
  text-align: center;
  font-size: 18px;
}
.process-detail {
  flex: 1;
  display: flex;
  padding: 24px;
  background-color: @background-color-light;
  min-height: 0;
  min-width: 0;
  overflow: scroll;
}
.node-conf-priority {
  margin-left: 16px;
  width: 120px;
}
.node-conf-item {
  margin-top: 20px;
  padding-top: 20px;
  border-top: var(--border);
}
.node-conf-label {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
  span {
    margin-left: 6px;
    font-weight: normal;
    font-size: 12px;
  }
}
.node-condition-item {
  position: relative;
  margin-top: 10px;
}
.node-condition-remove {
  position: absolute;
  top: 11px;
  right: 0;
  width: 18px;
  height: 18px;
  font-size: 18px;
  color: @text-color-secondary;
  cursor: pointer;
}
.add-condition {
  margin-top: 12px;
  color: @primary-color;
  cursor: pointer;
}
.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.temp-item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 16px;
  height: 36px;
  cursor: pointer;
  &:hover {
    background-color: @background-color-base;
    .temp-btn {
      display: flex;
    }
  }
}
.temp-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.temp-name {
  margin-left: 12px;
}
.temp-btn {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
  height: 14px;
  margin: auto 0;
  line-height: 1;
  &:hover {
    .txt-btn {
      text-decoration: underline;
    }
  }
}
</style>
